import AddIcon from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import SyncAlt from '@mui/icons-material/SyncAlt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { ConfigManager } from '../../../../../../../application/config/ConfigManager';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { ApplicationModelCustomInformationType } from '../../../../../../../service/application/entity/IApplicationCustomInformation';
import { IJiraIssueFieldType } from '../../../../../../../service/application/jira/IJiraIssueFieldsData';
import { JiraApplicationFieldDataValueType } from '../../../../../../../service/taskManager/IJiraTaskManager';
import { TaskManagerConnectionState } from '../../../../../../../service/taskManager/ITaskManager';
import { SpecificationFormSelection } from '../../../../../../admin/page/specification/parts/formSelection/SpecificationFormSelection';
import { Colors } from '../../../../../../design/color/Colors';
import { FormAutocomplete } from '../../../../../../design/form/FormAutocomplete';
import { FormBox } from '../../../../../../design/form/FormBox';
import { FormCheckboxField } from '../../../../../../design/form/FormCheckboxField';
import { FormIcon } from '../../../../../../design/form/FormIcon';
import { FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { FormTextLabel } from '../../../../../../design/form/FormTextLabel';
import { FormTitleText } from '../../../../../../design/form/FormTitleText';
import { ChipListInput } from '../../../../../../design/input/chipListInput/ChipListInput';
import { ProjectButton } from '../../../../../../design/link/ProjectButton';
import { isAcceptRequirementAvailableExtension } from '../../inject/isAcceptRequirementAvailableExtension';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { ApplicationDetailsType } from '../../store/ApplicationDetailsType';
import { ApplicationAcceptStatus } from '../../../../../../../service/application/entity/IApplicationModel';
import { ApplicationDetailsMode } from '../../store/ApplicationDetailsMode';

export interface IApplicationInfoDetailsFormProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const AcceptButton = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const isDraft =
    domain.ui.application.entity.acceptStatus === ApplicationAcceptStatus.draft ||
    domain.ui.editApplication.entity.acceptStatus === ApplicationAcceptStatus.draft;
  const isEditMode = domain.ui.mode.value === ApplicationDetailsMode.edit;
  const isHaveApplicationId = domain.ui.application.entity.id;
  const canCreateOrUpdate = domain.ui.isCanCreate.value || domain.ui.isCanUpdate.value;
  const isEntityType = domain.ui.type.value === ApplicationDetailsType.entity;

  return (isDraft || !isHaveApplicationId || isEditMode) && canCreateOrUpdate && isEntityType ? (
    <Grid item>
      <Button
        disabled={domain.ui.isDisabledSaveButton.value}
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<SaveIcon />}
        onClick={async () => {
          domain.setApplicationAcceptStatus(ApplicationAcceptStatus.review);
          await domain.saveApplication();
        }}
      >
        Отправить в ДРТЗИ
      </Button>
    </Grid>
  ) : (
    <></>
  );
});

export const ApplicationInfoDetailsForm = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const container = useContext(ContainerContext);
  const classes = useStyles();
  const isNew = !domain.ui.editApplication.entity.id;
  const isEntityType = domain.ui.type.value === ApplicationDetailsType.entity;
  const isHaveTemplates = domain.ui.applicationTemplates.list.length > 0;

  const QuestionnaireEditExtended = container.get(QuestionnaireEdit);

  const isAcceptRequirementAvailableExtensionHandlers = container.get(isAcceptRequirementAvailableExtension);
  const isAcceptRequirementAvailableExtensionValues = isAcceptRequirementAvailableExtensionHandlers.map((handler) =>
    handler(domain),
  );
  const isAcceptRequirementAvailable =
    isNew && isEntityType && !isAcceptRequirementAvailableExtensionValues.includes(false);
  const isSupportAcceptApplication = ConfigManager.getConfig().ui.application.acceptWithVersion;
  const isTemplatesAvailable = isNew && isEntityType && isHaveTemplates;
  const jiraProjectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const jiraIssueTypeId = domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id;
  const jiraAllowedStatuses = domain.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds;
  const isConnectedJira = domain.ui.editJiraTaskManager.entity.connectionState === TaskManagerConnectionState.connected;
  const performersList = domain.ui.performerTypesAutocompleteValues();
  if (performersList.length === 1) {
    domain.ui.editApplication.entity.performerTypeId = performersList[0].value;
  }
  let jiraNamePrefix = ConfigManager.getConfig().defaultValues?.jiraIssuePrefix || '';
  if (jiraNamePrefix.length > 0) {
    jiraNamePrefix = `${jiraNamePrefix} `;
  }

  const setTemplateData = () => {
    if (
      (domain.ui.editApplication.entity && isTemplatesAvailable && domain.ui.selectedApplicationTemplate.value === '',
        !domain.ui.isSetDefaultTemplate.value)
    ) {
      const defaultTemplate = domain.ui.applicationTemplates.list.find((template) => template.isDefault === true);

      if (
        domain.ui.isCompletePageStartLoading.value === true &&
        domain.ui.type.value !== ApplicationDetailsType.template &&
        defaultTemplate &&
        !domain.ui.editApplication.entity.id &&
        !domain.ui.isSetDefaultTemplate.value
      ) {
        domain.setApplicationTemplate(defaultTemplate.id);
        domain.ui.isSetDefaultTemplate.setValue(true);
      }
    }
  };

  useEffect(setTemplateData, [
    domain,
    domain.ui.isCompletePageStartLoading.value,
    domain.ui.editApplication.entity.id,
    domain.ui.selectedApplicationTemplate.value,
    domain.ui.applicationTemplates.list,
    domain.ui.isSetDefaultTemplate.value,
    isTemplatesAvailable,
    isNew,
  ]);

  useEffect(() => {
    if (isNew) {
      domain.ui.isNeedVerifyAllRequirementsOnCreate.setValue(!isAcceptRequirementAvailable);
    }
  }, [isAcceptRequirementAvailable]);

  useEffect(() => {
    if (domain.ui.editJiraTaskManager.entity.settings?.value?.projectId) {
      domain.loadJiraProjects();
    }
  }, [
    domain.ui.editJiraTaskManager.entity.settings?.value?.projectId,
    domain.ui.editJiraTaskManager.entity.serverId,
    domain,
  ]);

  useEffect(() => {
    if (domain.projectFormDomain) {
      domain.syncProjectAndApplicationDomains();
    }
  }, []);


  const JiraPrioritiesAutocompleteComponent = useContext(ContainerContext).get(JiraPrioritiesAutocomplete);

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container direction={'column'} spacing={4}>
        {isTemplatesAvailable && (
          <Grid item xs={6}>
            <FormAutocomplete
              values={domain.ui.applicationTemplateAutocompleteValues()}
              onChangeSelect={domain.setApplicationTemplateHandler}
              key="applicationTemplate"
              selected={domain.ui.selectedApplicationTemplate.value || null}
              label="Шаблон"
              helperText={
                'При выборе одного из заранее преднастроенных шаблонов в данном поле осуществляется предзаполнение полей Анкеты'
              }
            />
          </Grid>
        )}

        {domain.ui.type.value === ApplicationDetailsType.template && (
          <Grid item xs={6}>
            <IsDefaultTemplate domain={domain} />
          </Grid>
        )}
        <Grid style={{ ...(performersList.length === 1 && { display: 'none' }) }} item xs={6}>
          {performersList.length > 0 && domain.ui.editApplication.entity && (
            <PerformerInput
              list={performersList}
              domain={domain}
              errorMessage={domain.getApplicationValidationErrorFor('performerTypeId')?.message}
              onChange={(value) => {
                const handler = domain.getUpdateSelectFieldHandler('performerTypeId');
                domain.clearJiraLoadedDataValues(false, false, true, true);
                handler(value);
              }}
            />
          )}
        </Grid>

        {domain.ui.type.value === ApplicationDetailsType.template && (
          <Grid item key="customFields">
            {domain.ui.editApplication.entity.customInformation?.fields.map((customInformationField, index) => {
              const isStartItem = index === 0;
              const isEndItem = index === (domain.ui.editApplication.entity.customInformation?.fields.length || 1) - 1;
              const moveItems = function array_move(arr, old_index, new_index) {
                while (old_index < 0) {
                  old_index += arr.length;
                }
                while (new_index < 0) {
                  new_index += arr.length;
                }
                if (new_index >= arr.length) {
                  var k = new_index - arr.length + 1;
                  while (k--) {
                    arr.push(undefined);
                  }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
                return arr;
              };

              return (
                <Paper elevation={6} style={{ marginBottom: 30, padding: 16 }}>
                  <Grid container flexDirection={'row'}>
                    <Grid flexDirection="column" xs={10}>
                      <TextField
                        style={{ paddingBottom: 8, width: '100%' }}
                        required={true}
                        multiline
                        label={'Название поля'}
                        value={customInformationField.fieldName}
                        onChange={(event) => {
                          customInformationField.fieldName = event.target.value;
                        }}
                      // errorMessage={domain.ui.getQuestionError('text')}
                      />
                      <TextField
                        style={{ paddingBottom: 8, width: '100%' }}
                        required={false}
                        multiline
                        label={'Значение поля по умолчанию'}
                        value={customInformationField.value}
                        onChange={(event) => {
                          customInformationField.value = event.target.value;
                        }}
                      // errorMessage={domain.ui.getQuestionError('text')}
                      />
                      <TextField
                        style={{ paddingBottom: 8, width: '100%', maxHeight: 'none' }}
                        required={false}
                        multiline
                        label={'Описание поля'}
                        value={customInformationField.fieldDescription}
                        onChange={(event) => {
                          customInformationField.fieldDescription = event.target.value;
                        }}
                      // errorMessage={domain.ui.getQuestionError('text')}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container flexDirection={'column'}>
                        <Grid item style={{ padding: '10px 0 0 10px' }}>
                          <ProjectButton
                            action={() => {
                              if (domain.ui.editApplication.entity.customInformation) {
                                domain.ui.editApplication.entity.customInformation.fields =
                                  domain.ui.editApplication.entity.customInformation.fields.filter(
                                    (field) => field !== customInformationField,
                                  );
                              }
                            }}
                          >
                            Удалить поле
                          </ProjectButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            style={{ visibility: isStartItem ? 'hidden' : 'visible' }}
                            onClick={() =>
                              moveItems(
                                domain.ui.editApplication.entity.customInformation?.fields || [],
                                index,
                                index - 1,
                              )
                            }
                          >
                            <ArrowDropUp />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{ visibility: isEndItem ? 'hidden' : 'visible' }}
                            onClick={() =>
                              moveItems(
                                domain.ui.editApplication.entity.customInformation?.fields || [],
                                index,
                                index + 1,
                              )
                            }
                          >
                            <ArrowDropDown />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        )}
        <QuestionnaireEditExtended domain={domain} />

        {isAcceptRequirementAvailable && (
          <Grid item xs={6}>
            <FormCheckboxField
              value={domain.ui.isNeedVerifyAllRequirementsOnCreate.value}
              defaultValue={false}
              onChange={(value) => {
                domain.ui.isNeedVerifyAllRequirementsOnCreate.setValue(value);
              }}
              description={''}
              label={'Требуется верификация сформированных требований'}
            />
          </Grid>
        )}

        {isEntityType && domain.ui.isSupportJira.value && domain.ui.isCanEditJira.value && (
          <Grid item xs={6}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="application-jira-form"
                id="application-jira-form"
              >
                <Grid container spacing={2} direction={'row'}>
                  <Grid item>
                    <Typography>Jira</Typography>
                  </Grid>
                  <Grid item>
                    {isConnectedJira && <CloudDoneIcon style={{ color: Colors.positivePassive }} />}
                    {!isConnectedJira && <CloudOffIcon style={{ color: Colors.negativePassive }} />}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={'column'}>
                  <Grid item>
                    <FormAutocomplete
                      errorMessage={domain.getJiraValidationErrorFor('serverId')?.message}
                      values={domain.ui.taskManagerServersValues()}
                      onChangeSelect={(selected: any) => {
                        domain.ui.editJiraTaskManager.entity.serverId = selected.value;
                        domain.clearJiraLoadedDataValues(true, true, true, true);
                        domain.checkJiraConnectionByStates();
                      }}
                      selected={domain.ui.editJiraTaskManager.entity.serverId}
                      label="Соединение с Jira"
                    />
                  </Grid>
                  {domain.ui.editJiraTaskManager.entity.serverId && (
                    <Grid item>
                      <JiraProjectAutocomplete domain={domain} />
                    </Grid>
                  )}
                  {jiraProjectId && (
                    <Grid item>
                      <JiraIssueTypeAutocomplete domain={domain} />
                      {jiraProjectId && jiraIssueTypeId && (
                        <div style={{ padding: '15px' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={domain.applyTemplate}
                          >
                            Применить шаблон настроек
                          </Button>
                        </div>
                      )}
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item>
                      <FormTextLabel text={`Формат названия задачи: '${jiraNamePrefix}Короткое Название'`} />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item>
                      <JiraPrioritiesAutocompleteComponent domain={domain} />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item>
                      <JiraInternalStatusesToJiraSyncAutocomplete domain={domain} />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && Number(jiraAllowedStatuses?.length) > 0 && (
                    <Grid item>
                      <JiraStatusesAutocomplete domain={domain} />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item padding={2}>
                      <ChipListInput
                        id={'jira-labels-application-settingsForm'}
                        options={[]}
                        defaultValue={domain.ui.editJiraTaskManager.entity?.settings?.value?.customLabels || []}
                        label={'Метка (Label) Системы в Jira'}
                        placeholder={'Введите метку'}
                        onChange={(newValues) => {
                          if (domain.ui.editJiraTaskManager.entity.settings?.value) {
                            domain.ui.editJiraTaskManager.entity.settings.value.customLabels = newValues;
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item>
                      <JiraIssuesFieldsAutocomplete domain={domain} />
                    </Grid>
                  )}
                  {jiraProjectId && jiraIssueTypeId && (
                    <Grid item>
                      <JiraDeletedTaskStatusesAutocomplete domain={domain} />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Toolbar />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={domain.cancelEditApplication}>
              Отменить
            </Button>
          </Grid>
          {(domain.ui.isCanCreate.value === true || domain.ui.isCanUpdate.value === true) && (
            <Grid item>
              <Button
                disabled={domain.ui.isDisabledSaveButton.value}
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => {
                  if (domain.ui.editApplication.entity.acceptStatus === ApplicationAcceptStatus.accepted) {
                    domain.setApplicationAcceptStatus(ApplicationAcceptStatus.draft);
                  }
                  domain.saveApplication();
                }}
              >
                Сохранить
              </Button>
            </Grid>
          )}
          {isSupportAcceptApplication && <AcceptButton domain={domain} />}
        </Grid>
      </Grid>
    </Paper>
  );
});

const PerformerInput = observer(({ domain, list, errorMessage, onChange, style }: any) => {
  return (
    <FormAutocomplete
      style={style}
      errorMessage={errorMessage}
      values={list}
      onChangeSelect={onChange}
      key="performerTypeId"
      // selected={selected || ''}
      label="Тип исполнителей"
      required={true}
      helperText={
        'В зависимости от выбранного значения в сформированном наборе требований будет скрыта часть дополнительных колонок, полей или требований. Например, для внешних разработчиков'
      }
      selected={domain.ui.editApplication.entity.performerTypeId || null}
      selectedDefault={null}
    />
  );
});

const JiraProjectAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const projectsData = domain.ui.jiraProjects.value;
  const autocompleteValues: any = projectsData.map((projectData) => ({
    name: projectData.name || '',
    key: projectData.key || '',
    value: projectData.id || '',
  }));
  const isLoading = domain.ui.isLoadingJiraProjects.value;
  const [projectId, setProjectId] = useState(domain.ui.editJiraTaskManager?.entity?.settings?.value?.projectId);
  useEffect(() => {
    domain.loadJiraProjects();
  }, [domain.ui.editJiraTaskManager?.entity?.serverId]);

  let [isNeeenRerender, setIsNeeenRerender] = useState(true);;

  useEffect(() => {
    setIsNeeenRerender(false);
    setProjectId(domain.ui.editJiraTaskManager?.entity?.settings?.value?.projectId)
    setTimeout(() => {
      setIsNeeenRerender(true); 
      setProjectId(domain.ui.editJiraTaskManager?.entity?.settings?.value?.projectId)
    }, 10)
  }, [domain.ui.editJiraTaskManager?.entity?.settings?.value?.projectId]);

  return (
    isNeeenRerender ? <FormAutocomplete
      errorMessage={domain.getJiraValidationErrorFor('projectId')?.message}
      values={autocompleteValues}
      disabled={isLoading}
      onChangeSelect={(newValue) => {
        if (domain.ui.editJiraTaskManager.entity.settings?.value) {
          domain.ui.editJiraTaskManager.entity.settings.value.projectId = newValue?.value || null;
          setProjectId(newValue?.value);
          //@ts-ignore
          domain.ui.editJiraTaskManager.entity.settings.value.projectKey = newValue?.key || null;
        }
        domain.isTemplateJira.setValue(false);
        domain.checkJiraConnectionByStates();
        domain.clearJiraLoadedDataValues(false, true, true, true, true);
      }}
      key="jiraProjectId"
      selected={projectId}
      label="Проект"
      onOpen={() => {
        domain.removeJiraValidationErrors();
      }}
      isLoading={isLoading}
      loadingText={'Загрузка проектов'}
    />
    : <></>
  );
});

export const QuestionnaireEdit = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  return (
    <>
      {domain.ui.type.value === ApplicationDetailsType.entity && (
        <Grid item key="customFieldsData">
          {domain.ui.editApplication.entity.customInformation?.fields.map((customInformationField) => {
            return (
              <Grid container flexDirection={'row'}>
                <Grid item style={{ height: 200, width: '100%' }}>
                  <FormTextField
                    required={false}
                    multiline={true}
                    rows={4}
                    label={customInformationField.fieldName}
                    value={customInformationField.value}
                    onChange={(newValue) => {
                      customInformationField.value = newValue;
                    }}
                  // errorMessage={domain.ui.getQuestionError('text')}
                  />
                  <div style={{ marginLeft: 25 }}>
                    <FormHelperText>{customInformationField.fieldDescription}</FormHelperText>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
      {domain.ui.type.value === ApplicationDetailsType.template && (
        <Grid item xs={6} key="addCustomField">
          <div style={{ width: 350 }}>
            <FormBox>
              <ProjectButton
                icon={<AddIcon />}
                action={() => {
                  if (!domain.ui.editApplication.entity.customInformation) {
                    domain.ui.editApplication.entity.customInformation = { fields: [] };
                  }
                  domain.ui.editApplication.entity.customInformation.fields.push({
                    fieldDescription: '',
                    fieldName: '',
                    sortIndex: domain.ui.editApplication.entity.customInformation.fields.length + 1,
                    type: ApplicationModelCustomInformationType.multiline,
                    value: '',
                  });
                }}
              >
                Добавить дополнительное поле
              </ProjectButton>
            </FormBox>
          </div>
        </Grid>
      )}
      <Grid item xs={6}>
        <SpecificationFormSelection
          onChange={(specificationIds) => domain.onChangeApplicationSpecifications(specificationIds)}
          selectedSpecificationsIds={domain.ui.editApplication.entity.specificationsIds || []}
          specificationCategories={domain.ui.editSpecificationsCategories.list}
          specifications={domain.ui.editSpecifications.list}
        />
      </Grid>
    </>
  )
});

export const JiraPrioritiesAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const jiraProjectPriorities = domain.ui.jiraProjectPriorities.value;
  const jiraPrioritiesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraProjectPriorities].map((item) => ({
    name: item.name || '',
    value: item.id || '',
  }));

  const [internalTagsForPriorities, setInternalTagsForPriorities] = useState(domain.ui.jiraProjectTagsForPriority.value.reverse());

  const projectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const issueTypeId = domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id;

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraPriorities();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  useEffect(() => {

    setInternalTagsForPriorities([]);

    setTimeout(() => {
      setInternalTagsForPriorities(() => {
        return [...domain.ui.jiraProjectTagsForPriority.value.reverse()]
      })
    }, 1)

  }, [domain.isTemplateJira.value])

  return (
    <Grid container direction={'column'}>
      <FormTitleText text={'Приоритет по тэгу Критичность требования'} />
      {internalTagsForPriorities.map((tagForPriority) => {
        const isLoading = domain.ui.isLoadingJiraPriorities.value;
        const selectedPriorityId =
          domain.ui.editJiraTaskManager?.entity?.settings?.value?.prioritiesMap?.find(
            (link) => link.tagId === tagForPriority.tag.id,
          )?.externalPriorityId || null;
        const selectedName = domain.ui.editJiraTaskManager?.entity?.settings?.value?.prioritiesMap?.find(
          (link) => link.tagId === tagForPriority.tag.id,
        )?.name || null;
        if (domain.isTemplateJira.value) {
          const jiraStatus = jiraPrioritiesAutocompleteValues.find((item) => item.name === selectedName);
          if (jiraStatus) {
            const index = domain.ui.editJiraTaskManager?.entity?.settings?.value?.prioritiesMap?.findIndex(
              (link) => link.tagId === tagForPriority.tag.id,
            )
            if (domain.ui.editJiraTaskManager?.entity?.settings?.value?.prioritiesMap) {
              if (index && index !== -1) {
                domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap[index].externalPriorityId = jiraStatus.value;
              }
            }
          }
        }
        return (
          <Grid item>
            <Grid xs={8} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
              <Grid xs={1} item>
                <FormTextLabel text={tagForPriority.tag.name} />
              </Grid>
              <Grid item>
                <FormIcon>
                  <SyncAlt color={!!selectedPriorityId ? 'secondary' : 'disabled'} />
                </FormIcon>
              </Grid>
              <Grid item xs={4}>
                <FormAutocomplete
                  values={isLoading ? [] : jiraPrioritiesAutocompleteValues}
                  onChangeSelect={(newValue) => {
                    if (domain.ui.editJiraTaskManager.entity.settings?.value) {
                      if (!domain.ui.editJiraTaskManager.entity.settings?.value?.prioritiesMap) {
                        domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap = [];
                      }
                      let selectedJiraPriorityIndex =
                        domain.ui.editJiraTaskManager.entity.settings?.value?.prioritiesMap.findIndex(
                          (item) => item.tagId === tagForPriority.tag.id,
                        );
                      if (domain.isTemplateJira.value) {
                        selectedJiraPriorityIndex = domain.ui.editJiraTaskManager.entity.settings?.value?.prioritiesMap.findIndex(
                          (item) => item.tagId === tagForPriority.tag.id ||
                            (item.name === tagForPriority.name),
                        );
                      }
                      if (!newValue?.value) {
                        domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap.splice(
                          selectedJiraPriorityIndex,
                          1,
                        );
                      } else {
                        if (selectedJiraPriorityIndex !== -1) {
                          domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap[
                            selectedJiraPriorityIndex
                          ].externalPriorityId = newValue.value;
                          domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap[
                            selectedJiraPriorityIndex
                          ].name = newValue.name;
                        } else {
                          domain.ui.editJiraTaskManager.entity.settings.value.prioritiesMap.push({
                            isDefault: tagForPriority.isDefault,
                            orderIndex: tagForPriority.orderIndex,
                            externalPriorityId: newValue.value,
                            tagId: tagForPriority.tag.id || '',
                            name: tagForPriority.name || '',
                          });
                        }
                      }
                    }
                  }}
                  key={`jira-priority-map-${tagForPriority.tag.id}`}
                  selected={selectedPriorityId}
                  label="Приоритет в Jira"
                  onOpen={() => {
                    domain.removeJiraValidationErrors();
                  }}
                  // onClose={() => changeIsloading(false)}
                  isLoading={isLoading}
                  loadingText={'Загрузка приоритетов'}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

export const JiraIssuesFieldsAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const projectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const issueTypeId = domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id;
  const classes = useStyles();
  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraIssuesFields();
        domain.ui.jiraIssuesFields.value.fields
          .filter(
            (item) =>
              item.type !== IJiraIssueFieldType.notAllowed ||
              (item.type === IJiraIssueFieldType.notAllowed && item.required),
          )
          .forEach((field) => {
            const fieldData = domain.ui.editJiraTaskManager.entity.settings?.value?.fields?.find(
              (item) => item.fieldKey === field.key,
            );
            if (!fieldData) {
              domain.ui.editJiraTaskManager.entity.settings?.value?.fields?.push({
                fieldKey: field?.key,
                fieldType: field?.type || IJiraIssueFieldType.notAllowed,
                isRequired: field?.required || false,
                fieldValueSettings: { type: JiraApplicationFieldDataValueType.noAction, settings: {} },
              });
            } else {
              if (!fieldData.fieldType) {
                fieldData.fieldType = field?.type || IJiraIssueFieldType.notAllowed;
              }
            }
          });
        if (domain.ui.editJiraTaskManager.entity.settings?.value?.fields) {
          if (!domain.ui.editJiraTaskManager.entity.settings.value.fields.length) {
            domain.isEmptyJiraFilds.setValue(true);
          } else {
            domain.isEmptyJiraFilds.setValue(false);
          }
        }
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  useEffect(() => {
    const fields = domain.ui.jiraIssuesFields.value.fields;
    domain.ui.jiraIssuesFields.value.fields = [];

    setTimeout(() => {
      domain.ui.jiraIssuesFields.value.fields = fields;
    }, 1)
    if (domain.isEmptyJiraFilds.value) {
      if (domain.ui.editJiraTaskManager.entity.settings?.value?.fields) {
        domain.ui.editJiraTaskManager.entity.settings.value.fields = [];
      }
    }
  }, [domain.isTemplateJira.value])

  return (
    <Grid container direction={'column'}>
      {domain.ui.jiraIssuesFields.value.fields?.filter((item) => item.type !== IJiraIssueFieldType.notAllowed).length >
        0 && (
          <Grid item>
            <FormTitleText text={'Jira поля'} />
          </Grid>
        )}
      {domain.ui.jiraIssuesFields.value.fields.map((field) => {
        const taskManagerFieldData = domain.ui.editJiraTaskManager.entity.settings?.value?.fields?.find((item) => {
          return item.fieldKey === field.key;
        });
        if (taskManagerFieldData) {
          taskManagerFieldData.isRequired = field.required;
        }
        const error = domain.ui.jiraValidationErrors.list.find((item) => {
          return item.target === `field-${field.key}`;
        });
        return (
          <Grid item style={{ width: '98%' }}>
            <Grid xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
              {field.type !== IJiraIssueFieldType.notAllowed && (
                <Grid xs={2} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={error && Colors.negativePassive}
                    text={field.required ? `${field.name}*` : field.name}
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.notAllowed && field.required && (
                <Grid xs={2} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={Colors.negativePassive}
                    text={field.required ? `${field.name}*` : field.name}
                  />
                </Grid>
              )}
              {field.type !== IJiraIssueFieldType.notAllowed && (
                <Grid xs={1} item className={classes.formControl}>
                  <FormIcon>
                    <ArrowRightIcon
                      color={
                        taskManagerFieldData?.fieldValueSettings?.type !== JiraApplicationFieldDataValueType.noAction
                          ? 'secondary'
                          : 'disabled'
                      }
                    />
                  </FormIcon>
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.notAllowed && field.required && (
                <Grid xs={4} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={Colors.negativePassive}
                    text={' Поле обязательно для заполнения и не поддерживается интеграцией'}
                  />
                </Grid>
              )}

              {field.type === IJiraIssueFieldType.singleRowString && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                      { value: JiraApplicationFieldDataValueType.requirementShortName, name: 'Название требования' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.multiRowString && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                      { value: JiraApplicationFieldDataValueType.requirementShortName, name: 'Название требования' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.stringArray && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior && (
                  <Grid item xs={1} className={classes.formControl}>
                    <FormIcon>
                      <ArrowRightIcon
                        color={
                          (taskManagerFieldData?.fieldValueSettings?.type as unknown) !==
                            JiraApplicationFieldDataValueType.noAction
                            ? 'secondary'
                            : 'disabled'
                        }
                      />
                    </FormIcon>
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.singleRowString && (
                  <Grid item xs={6}>
                    <FormTextField
                      onChange={(value) => {
                        if (taskManagerFieldData) {
                          taskManagerFieldData.fieldValueSettings.settings.value = value;
                        }
                      }}
                      value={taskManagerFieldData?.fieldValueSettings?.settings?.value}
                      label={'Значение текстового поля ' + field?.name}
                      errorMessage={error?.message}
                    />
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.multiRowString && (
                  <Grid item xs={6}>
                    <FormTextArea
                      onChange={(value) => {
                        if (taskManagerFieldData) {
                          taskManagerFieldData.fieldValueSettings.settings.value = value;
                        }
                      }}
                      value={taskManagerFieldData?.fieldValueSettings?.settings?.value}
                      label={'Значение текстового поля ' + field?.name}
                      errorMessage={error?.message}
                    />
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.stringArray && (
                  <Grid item xs={6}>
                    <Grid item>
                      <ChipListInput
                        id={`jira-labels-${field?.name}-application-form`}
                        options={[]}
                        defaultValue={taskManagerFieldData?.fieldValueSettings?.settings?.value || []}
                        label={`Поле ${field?.name}`}
                        placeholder={'Введите метку и нажмите Enter'}
                        onChange={(newValues) => {
                          if (taskManagerFieldData?.fieldValueSettings?.settings) {
                            taskManagerFieldData.fieldValueSettings.settings.value = newValues;
                          }
                        }}
                        errorMessage={error?.message}
                        helperText={`Метка (${field?.name}) не должна содержать пробелов и не должа быть больше 255 символов`}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

const JiraStatusesAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const [statuses, setStatuses] = useState<any>([]);
  useEffect(() => {
    const performer = domain.ui.editPerformerTypes.list.find(
      (item) => item.id === domain.ui.editApplication.entity.performerTypeId,
    );
    if (performer) {
      const statuses = domain.ui.editStatuses.list.filter((item) => performer.statusesIds?.includes(item.id || ''));
      setStatuses(statuses);
      const allowedInternalStatuses = domain.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds || [];
      setAllowedStatuses(() => [...statuses.filter((status) => allowedInternalStatuses.includes(status.id || ''))])
    }
  }, [domain.ui.editApplication.entity.performerTypeId]);

  const projectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const issueTypeId = domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id;
  const allowedInternalStatuses =
    domain.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds || [];
  const isLoading = domain.ui.isLoadingJiraStatuses.value;
  const [allowedStatuses, setAllowedStatuses] = useState(() => statuses.filter((status) => allowedInternalStatuses.includes(status.id || '')));

  useEffect(() => {
    setTimeout(() => {
      const allowedInternalStatuses =
        domain.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds || [];
      setAllowedStatuses(() => [...statuses.filter((status) => allowedInternalStatuses.includes(status.id || ''))]);
    }, 1)
  }, [domain.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds, statuses, domain.ui.editApplication.entity.performerTypeId])

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraStatuses();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  return (
    <Grid container direction={'column'}>
      {allowedStatuses.map((allowedStatus) => {
        const statusesValues = domain.ui.statusesValues.list.filter((item) => item.statusId === allowedStatus.id);

        return (
          <Grid item>
            <FormTitleText text={allowedStatus.name} />
            {statusesValues.map((statusValue) => {
              const jiraStatusesData = domain.ui.jiraProjectStatuses.value;
              const jiraStatusesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraStatusesData].map((item) => ({
                name: item.name || '',
                value: item.id || '',
              }));
              if (statusValue.isActive) {
                const statusesMap = domain.ui.editJiraTaskManager.entity.settings?.value?.statusesMap || [];
                let selectedJiraStatusId = statusesMap.find(
                  (item) => item.internalStatusValueId === statusValue.id && item.internalStatusId === allowedStatus.id,
                )?.externalStatusId;
                if (domain.isTemplateJira.value) {
                  const templateStatus = domain.ui.editJiraTaskManager.entity.settings?.value?.statusesMap.find((status) => {
                    return status.internalStatusValueId === statusValue.id;
                  })
                  const externalStatusID = jiraStatusesAutocompleteValues.filter((item) => item.name === templateStatus?.name).map((item) => item.value)[0];
                  if (templateStatus) {
                    templateStatus.externalStatusId = externalStatusID;
                  }
                  selectedJiraStatusId = externalStatusID;
                }
                return (
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid xs={2} item>
                      <FormTextLabel text={statusValue.name} />
                    </Grid>
                    <Grid item>
                      <FormIcon>
                        <SyncAlt color={selectedJiraStatusId ? 'secondary' : 'disabled'} />
                      </FormIcon>
                    </Grid>
                    <Grid item xs={4}>
                      <FormAutocomplete
                        values={isLoading ? [] : jiraStatusesAutocompleteValues}
                        onChangeSelect={(newValue) => {
                          if (domain.ui.editJiraTaskManager.entity.settings?.value) {
                            if (!domain.ui.editJiraTaskManager.entity.settings?.value?.statusesMap) {
                              domain.ui.editJiraTaskManager.entity.settings.value.statusesMap = [];
                            }
                            const selectedJiraStatusIndex =
                              domain.ui.editJiraTaskManager.entity.settings?.value?.statusesMap.findIndex(
                                (item) =>
                                  item.internalStatusValueId === statusValue.id &&
                                  item.internalStatusId === allowedStatus.id,
                              );
                            if (!newValue?.value) {
                              domain.ui.editJiraTaskManager.entity.settings.value.statusesMap.splice(
                                selectedJiraStatusIndex,
                                1,
                              );
                            } else {
                              if (selectedJiraStatusIndex !== -1) {
                                domain.ui.editJiraTaskManager.entity.settings.value.statusesMap[
                                  selectedJiraStatusIndex
                                ].externalStatusId = newValue.value;
                                domain.ui.editJiraTaskManager.entity.settings.value.statusesMap[
                                  selectedJiraStatusIndex
                                ].name = newValue.name;
                              } else {
                                domain.ui.editJiraTaskManager.entity.settings.value.statusesMap.push({
                                  externalStatusId: newValue.value,
                                  internalStatusId: allowedStatus.id || '',
                                  internalStatusValueId: statusValue.id || '',
                                  name: statusValue.name,
                                });
                              }
                            }
                          }
                        }}
                        key={`jira-status-map-${statusValue.id}`}
                        selected={selectedJiraStatusId}
                        label="Статус в Jira"
                        onOpen={() => {
                          domain.removeJiraValidationErrors();
                        }}
                        // onClose={() => changeIsloading(false)}
                        isLoading={isLoading}
                        loadingText={'Загрузка статусов'}
                      />
                    </Grid>
                  </Grid>
                );
              }
            })}
          </Grid>
        );
      })}
    </Grid>
  );
});


const JiraInternalStatusesToJiraSyncAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const [statusesAutocompleteValues, setStatusesAutocompleteValues] = useState<any>([]);
  useEffect(() => {
    const performer = domain.ui.editPerformerTypes.list.find(
      (item) => item.id === domain.ui.editApplication.entity.performerTypeId,
    );
    if (performer) {
      const statuses = domain.ui.editStatuses.list.filter((item) => performer.statusesIds?.includes(item.id || ''));
      const statusesAutocompleteValues: any = statuses.map((data) => ({
        name: data.name || '',
        value: data.id || '',
      }));
      setStatusesAutocompleteValues(statusesAutocompleteValues);
    }
  }, [domain.ui.editApplication.entity.performerTypeId]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <FormMultiAutocomplete
          values={statusesAutocompleteValues}
          onChangeSelect={(newValue: any) => {
            if (domain.ui.editJiraTaskManager?.entity?.settings?.value) {
              domain.ui.editJiraTaskManager.entity.settings.value.allowedInternalStatusesIds = newValue.map(
                (value: any) => value.value,
              );
            }
          }}
          key="allowedInternalStatusesIds"
          selected={domain.ui.editJiraTaskManager.entity?.settings?.value?.allowedInternalStatusesIds || []}
          label="Статусы для синхронизации"
        />
      </Grid>
    </Grid>
  );
});

const JiraIssueTypeAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const issueTypes = domain.ui.jiraIssueTypes.value;
  const projectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const autocompleteValues: any = issueTypes.map((data) => ({
    name: data.name || '',
    value: data.id || '',
  }));
  const isLoading = domain.ui.isLoadingJiraIssueTypes.value;

  useEffect(() => {
    domain.isTemplateJira.setValue(false);
  }, [])

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraIssueTypes();
      };
      loadData();
    }
  }, [projectId, domain]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <FormAutocomplete
          errorMessage={domain.getJiraValidationErrorFor('issueType')?.message}
          values={isLoading ? [] : autocompleteValues}
          onChangeSelect={(newValue) => {
            if (domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type) {
              domain.ui.editJiraTaskManager.entity.settings.value.issue.type = {
                id: newValue?.value || null,
                name: newValue?.name || null,
              };
            }
            domain.clearJiraLoadedDataValues(false, false, true, true);
            domain.checkJiraConnectionByStates();
          }}
          key="issueType"
          selected={domain.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id}
          label="Тип задачи"
          onOpen={() => {
            //domain.removeJiraValidationErrors();
          }}
          // onClose={() => changeIsloading(false)}
          isLoading={isLoading}
          loadingText={'Загрузка типов задач'}
        />
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(6),
  },
  formControl: {
    padding: theme.spacing(2),
  },
}));

const IsDefaultTemplate = observer(({ domain }: { domain: ApplicationDetailDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.editApplication.entity.isDefault);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.editApplication.entity.isDefault = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.editApplication.entity.isDefault);
  }, [domain.ui.editApplication.entity.isDefault]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} />}
      label="Шаблон по умолчанию"
    />
  );
});

const JiraDeletedTaskStatusesAutocomplete = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const projectId = domain.ui.editJiraTaskManager.entity?.settings?.value?.projectId;
  const issueTypeId = domain.ui.editJiraTaskManager.entity?.settings?.value?.issue?.type?.id;
  let isLoading = domain.ui.isLoadingJiraStatuses.value;

  const jiraStatusesData = domain.ui.jiraProjectStatuses.value;
  let jiraStatusesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraStatusesData].map((item) => ({
    name: item.name || '',
    value: item.id || '',
  }));

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraStatuses();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  const [selectedStatus, setSelectedStatus] = useState(domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.externalPriorityId);

  useEffect(() => {
    isLoading = true;
    setTimeout(() => {
      isLoading = false;
      if(domain.isTemplateJira.value) {
        const jiraStatus = jiraStatusesData.find((item) => item.name === domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.name);
        setSelectedStatus(jiraStatus.id);
        if (domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.externalPriorityId) {
          domain.ui.editJiraTaskManager.entity.settings.value.deletedStatus.externalPriorityId = jiraStatus.id;
        }
      }
    }, 100)

  }, [domain.isTemplateJira.value])

  useEffect(() => {
    setSelectedStatus(domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.externalPriorityId);
  }, [domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.externalPriorityId])

  return (
    <Grid item>
      <FormTitleText text={'Статус для неактуальных требований'} />
      {!isLoading ? <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
        <Grid xs={2} item>
          <FormTextLabel text={'Требование не актуально'} />
        </Grid>
        <Grid item>
          <FormIcon>
            <SyncAlt color={domain.ui.editJiraTaskManager.entity?.settings?.value?.deletedStatus?.externalPriorityId ? 'secondary' : 'disabled'} />
          </FormIcon>
        </Grid>
        <Grid item xs={4}>
        <FormAutocomplete
            values={isLoading ? [] : jiraStatusesAutocompleteValues}
            onChangeSelect={(newValue) => {
              if (domain.ui.editJiraTaskManager.entity?.settings?.value) {
                if (!domain.ui.editJiraTaskManager.entity.settings.value.deletedStatus?.externalPriorityId) {
                  domain.ui.editJiraTaskManager.entity.settings.value.deletedStatus = { externalPriorityId : '', name: ''};
                }
                domain.ui.editJiraTaskManager.entity.settings.value.deletedStatus.externalPriorityId = newValue?.value || '';
                domain.ui.editJiraTaskManager.entity.settings.value.deletedStatus.name = newValue?.name || '';
              }
            }}
            key={`jira-status-map-${''}`}
            selected={selectedStatus}
            label="Статус в Jira"
            onOpen={() => {
              domain.removeJiraValidationErrors();
            }}
            isLoading={isLoading}
            loadingText={'Загрузка статусов'}
          />
        </Grid>
      </Grid> : <></>}
    </Grid>
  );
});
